<template>
  <div class="all-accommodation-types">
    <AdminTable title="All Accommodation Types" :list="accommodationTypeList" :fields="fields" :loading="loading"
    @update-list="update" @edit="editAccommodationType"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import { accommodationTypeService } from '@/services/admin'

export default {
  name: 'AdminAllAccommodationTypes',
  components: {
    AdminTable,
  },
  data() {
    return {
      accommodationTypeList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'title', label: 'Title' },
        { key: 'subtitle', label: 'Sub title'},
        { key: 'isShared', label: 'Shared', type: 'boolean' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit'] },
      ]
    }
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    async update() {
      this.loading = true
      this.accommodationTypeList = await accommodationTypeService.getAccommodationTypes()
      this.$store.commit('accommodationTypeStore/setAccommodationTypes', this.accommodationTypeList)
      this.loading = false
    },
    editAccommodationType(accommodationType) {
      this.$store.commit('accommodationTypeStore/setAccommodationType', accommodationType)
      this.$router.push({ name: 'accommodation-type-edit', params: { id: accommodationType.id } })
    }
  }
}
</script>

<style>

</style>
